import React from "react"
import styled from "styled-components"

import { useModal } from "../hooks/modal"

const Link = styled.a`
  display: inline-block;
  text-decoration: none;
  color: ${props => props.theme.textColor};
`

export default function LightboxThumb({ images, index, ...props }) {
  const { openModal } = useModal()

  const handleClick = (e) => {
    e.preventDefault()
    openModal(e, "lightbox", { images, index })
  }

  return (
    <Link href={images[index].path} onClick={handleClick} {...props}>
      {images[index].Thumb}
    </Link>
  )
}

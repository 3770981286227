import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import get from "lodash/get"

import DownloadIcon from "./icons/Download"
import LightboxThumb from "./LightboxThumb"

import nl2br from "../utils/nl2br"

const Title = styled.h3`
  margin: 0 0 0.5rem;
  font: bold 1rem/1.125rem ${props => props.theme.secondaryFont};
  color: #333;
`

const List = styled.ul`
  margin: 1rem 0 1.8125rem;
  padding: 0;
  border-top: 1px solid rgba(226, 0, 0, 0.15);
  list-style: none;
`

const ListItem = styled.li`
  display: flex;
  width: 100%;
  padding: 0.625rem 0;
  border-bottom: 1px solid rgba(226, 0, 0, 0.15);
`

const ImageWrapper = styled.div`
  width: 24.8%;
  margin-right: 6.5%;

  img[src] {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const FileDescription = styled.p`
  width: 68.7%;
  margin: 0;
`

const DownloadWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
  width: 3.3125rem;

  a[href] {
    display: block;
    width: 2.6875rem;
    height: 2.75rem;
    text-align: center;
    font: bold 0.875rem/1.125rem ${props => props.theme.secondaryFont};
    text-transform: uppercase;
    text-decoration: none;
    color: #333;
  }
`

const StyledDownloadIcon = styled(DownloadIcon)`
  margin-top: -0.3125rem;

  path {
    fill: #000;
  }

  line {
    stroke: #000;
  }
`

export default function FileList({ lang, list }) {
  const intl = useIntl()
  const { title, description, files } = list

  const getFileExtension = (filename) => {
    if (!filename) {
      return ""
    }
    const array = filename.split(".")
    return array[array.length - 1]
  }

  const images = files.map(({ thumbnail, altText }) => ({
    Thumb: (
      <img
        src={`/${thumbnail}?nf_resize=fit&w=274`}
        alt={altText || ""}
        {...(altText ? {} : { role: "presentation" })}
      />
    ),
    FullSize: (
      <img
        src={`/${thumbnail}?nf_resize=fit&w=1000`}
        alt={altText || ""}
        {...(altText ? {} : { role: "presentation" })}
      />
    ),
    path: thumbnail,
    maxWidth: "500px",
  }))

  return (
    <div>
      {title && <Title>{title}</Title>}
      {description && <p>{description}</p>}
      <List>
        {files.map((file, index) => (
          <ListItem key={file.file}>
            <ImageWrapper>
              <LightboxThumb
                images={images}
                index={index}
              />
            </ImageWrapper>
            <FileDescription dangerouslySetInnerHTML={{ __html: nl2br(file.description) }} />
            <DownloadWrapper>
              <a href={`/${file.file}`} download={`/${file.file}`}>
                <span className="structural">{intl.formatMessage({ id: "download" })}</span>
                {getFileExtension(file.file)}
                <StyledDownloadIcon />
              </a>
            </DownloadWrapper>
          </ListItem>
        ))}
      </List>
    </div>
  )
}

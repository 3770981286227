import React from "react"
import styled from "styled-components"

import RollupFrame from "./RollupFrame"
import LightboxThumb from "./LightboxThumb"

const Images = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 1rem -0.5rem 0;
  padding: 0;
  list-style: none;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  li {
    width: calc(50% - 1rem);
    margin: 0 0.5rem 1rem;
  }

  @media (min-width: 450px) {
    li {
      width: calc(33.33% - 1rem);
    }
  }
`

const Figure = styled.figure`
  width: 100%;
  margin: 0;
`

export default function ImageGallery({ title, frame, images }) {
  const getImageWithFrame = (img) => frame === "rollup"
    ? <RollupFrame>{img}</RollupFrame>
    : img

  const getImage = ({ image, altText, caption, isThumb = false }) => {
    const img = <img src={`/${image}${isThumb ? "?nf_resize=fit&w=384" : ""}`} alt={altText} />

    return (
      caption ?
        <Figure>
          {getImageWithFrame(img)}
          <figcaption>{caption}</figcaption>
        </Figure>
      :
        getImageWithFrame(img)
    )
  }

  const lightboxImages = images.map((image) => ({
    Thumb: getImage({ ...image, isThumb: true }),
    FullSize: getImage({ ...image, caption: false }),
    path: image.image,
    maxWidth: "500px",
  }))

  return (
    <>
      {title && <h2>{title}</h2>}
      <Images>
        {images.map((image, index) => (
          <li key={image.image}>
            <LightboxThumb
              images={lightboxImages}
              index={index}
            />
          </li>
        ))}
      </Images>
    </>
  )
}

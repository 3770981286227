import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import { Link } from "gatsby"
import get from "lodash/get"

import ToggleControl from "./ToggleControl"
import ImageGallery from "./ImageGallery"
import FileList from "./FileList"
import CookieSettings from "./CookieSettings"

import { useStore } from "../hooks/store"
import useIsClient from "../hooks/isClient"
import trackEvent from "../utils/track-event"
import { isRTL } from "../languages"

const ContentWrapper = styled.article`
  max-width: 40rem;
  min-height: calc(100% + 3rem);
  margin: 0 auto -3rem;
  padding: 1.5rem 1rem 3rem;
  overflow: hidden;
  ${props => props.rtl && `font-family: ${props.theme.rtlFont};`}
  background: ${props => props.theme.textPageContentBackgroundColor};

  @media (min-width: 820px) {
    max-width: 48.25rem;
    padding-left: 5.125rem;
    padding-right: 5.125rem;
  }
`

const ActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > * {
    margin-left: 0.5rem;
  }

  label {
    font-family: ${props => props.theme.headingFont};
    text-transform: uppercase;
    font-size: 0.875rem;
  }
`

const Title = styled.h1`
  color: ${props => props.theme.headingColor};

  @media only screen and (min-width: 500px) {
    text-align: center;
  }
`

const Preamble = styled.div`
  margin-bottom: 2rem;
  font-size: ${props => props.rtl ? "1.3125" : "1.1875"}rem;
  line-height: ${props => props.easyRead ? 2 : 1.4736 };
  color: ${props => props.theme.preambleColor};
`

const Body = styled.div`
  ${props => props.rtl && "font-size: 1.125rem;"}
  ${props => props.easyRead && "line-height: 2;"}

  h2 {
    margin-bottom: 0.25rem;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  a {
    color: ${props => props.theme.linkColor};
  }

  figure {
    margin: 0;
  }

  figure.global-image {
    margin-top: 1rem;
  }

  @media (min-width: 500px) {
    ${props => !props.easyRead && `
      img.half-width {
        width: 50%;
        float: right;
        margin: 0.25rem 0 1rem 1rem;
      }
    `}

    figure.align-left {
      max-width: 50%;
      float: left;
      margin-right: 1rem;
      margin-bottom: 1rem;
      margin-top: 0.25rem;
    }

    figure.align-right {
      max-width: 50%;
      float: right;
      margin-left: 1rem;
      margin-bottom: 1rem;
      margin-top: 0.25rem;
    }
  }

  figcaption {
    margin-top: 0;
    font-size: 0.9375rem;
    color: #707070;
    font-style: italic;
    background: #FDF5F5;
    padding: 0.5rem 1rem;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .reference {
    margin-top: 2rem;
    box-sizing: border-box;
    background: #fff;
    padding: 1rem;
    border-radius: 10px;
    font-size: 0.875rem;
  }

  .reference h2 {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.file-section {
    h2 {
      margin: 3.5rem 0 1.3125rem;
      font-family: ${props => props.theme.headingFont};
      color: #333;

      + ul {
        margin-top: 0;
      }
    }

    > ul {
      margin: 3.5rem 0 0;
      padding: 0;
      list-style: none;
    }
  }
`

const WidgetWrapper = styled.div`
  margin-top: 2rem;

  p {
    margin: 0;
  }

  table {
    width: 100%;
    margin-bottom: 1rem;
    border-collapse: collapse;
  }

  thead tr {
    color: #fff;
    background: #e20000;
  }

  th {
    text-align: ${props => props.rtl ? "right" : "left"};
  }

  th, td {
    padding: 0.5rem 0.25rem;
  }

  tbody tr:nth-child(even) {
    background: #F9DFDD;
  }

  tbody tr:nth-child(odd) {
    background: #FDF5F5;
  }

  @media (max-width: 649px) {
    th, td {
      font-size: 0.875rem;
    }

    th:first-child,
    td:first-child {
      padding-left: 0.5rem;
    }

    th:last-child,
    td:last-child {
      padding-right: 0.5rem;
    }
  }

  @media (min-width: 650px) {
    th, td {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    td:nth-child(2) {
      white-space: nowrap;
    }
  }
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  img {
    height: auto;
  }
`

export default function TextContent({
  lang,
  title,
  noMultiLang,
  preamble,
  body,
  easyReadTitle,
  easyReadPreamble,
  easyReadBody,
  images,
  imageGalleries,
  files,
  widget,
  template,
  children,
}) {
  const BREAKPOINT_MOBILE = 570
  const intl = useIntl()
  const easyReadAvailable = !!easyReadBody
  const [store, updateStore] = useStore()
  const { isEasyReadEnabled } = store
  const [preambleText, setPreambleText] = useState(preamble)
  const [bodyText, setBodyText] = useState(body)
  const [pageImages, setPageImages] = useState(images || [])
  const { key, isClient } = useIsClient()
  const rtl = !noMultiLang && isRTL(lang)
  const logos = pageImages.filter((img) => img.positionAndSize === "l1")
  const imageGalleriesWithId = (imageGalleries || []).map((gallery, index) => ({
    ...gallery,
    id: gallery.title || index,
  }))

  const getImage = (img) => {
    const leftAligned = img.positionAndSize == "hl"
    const rightAligned = img.positionAndSize === "hr"
    const src = `/${encodeURI(img.image)}`
    const isEasyRead = isEasyReadEnabled && easyReadBody

    return (
      <Body key={`${img.image}_${key}`} {...(lang !== "sv" && lang !== "en" ? { lang: "en" } : {})}>
        <figure
          className={`
            global-image
            ${!isEasyRead && leftAligned ? "align-left" : ""}
            ${!isEasyRead && rightAligned ? "align-right" : ""}
          `}
        >
          <img
            src={src}
            alt={img.altText}
            srcSet={`${src}?nf_resize=fit&w=200 200w, ${src}?nf_resize=fit&w=400 400w, ${src}?nf_resize=fit&w=640 640w`}
            sizes="(max-width: 640px) 100vw, 640px"
            {...(!noMultiLang && lang !== "sv" && lang !== "en" ? { lang: "en" } : {})}
          />
          {img.caption && <figcaption>{img.caption}</figcaption>}
        </figure>
      </Body>
    )
  }

  const hasFiles = !!(files || []).find((a) => (a.lists || []).find((b) => (b.files || []).length))

  useEffect(() => {
    if (isEasyReadEnabled) {
      setPreambleText(easyReadPreamble)
      setBodyText(easyReadBody)
    }
  }, [])

  useEffect(() => {
    setPreambleText(isEasyReadEnabled && easyReadPreamble ? easyReadPreamble : preamble)
    setBodyText(isEasyReadEnabled && easyReadBody ? easyReadBody : body)
  }, [isEasyReadEnabled, easyReadPreamble, easyReadBody])

  return (
    <ContentWrapper lang={noMultiLang ? (lang === "sv" ? "sv" : "en") : lang} {...(rtl ? { dir: "rtl" } : {})} rtl={rtl}>
      {easyReadAvailable && (
        <ActionBar>
          <label htmlFor="settings-easy-read">
            {intl.formatMessage({ id: "easyRead" })}
          </label>
          <ToggleControl
            buttonID="settings-easy-read"
            value={isEasyReadEnabled}
            onChange={value => {
              trackEvent({ category: "Settings", action: !store.isEasyReadEnabled ? "Activate" : "Deactivate", name: "Easy Read" })
              updateStore(store => {
                store.isEasyReadEnabled = !store.isEasyReadEnabled
              })
            }}
          />
        </ActionBar>
      )}
      <Title {...(noMultiLang && lang !== "sv" && lang !== "en" ? { lang } : {})}>
        {(isEasyReadEnabled && easyReadTitle) ? easyReadTitle : title}
      </Title>
      <div>
        {pageImages.filter((img) => img.positionAndSize === "f1").map(getImage)}
        {preambleText &&
          <Preamble
            key={`preamble_${key}`}
            dangerouslySetInnerHTML={{ __html: preambleText }}
            rtl={rtl}
            easyRead={isEasyReadEnabled && easyReadPreamble}
          />
        }
        {pageImages.filter((img) => img.positionAndSize === "f2" || img.positionAndSize.startsWith("h")).map(getImage)}
        {template === "cookies" &&
          <Body key={`cookies_${key}`}>
            <a href="#cookies">{intl.formatMessage({ id: "cookieUpdateLinkText" })}</a>
          </Body>
        }
        {bodyText &&
          <Body
            key={`body_${key}`}
            dangerouslySetInnerHTML={{ __html: bodyText }}
            rtl={rtl}
            easyRead={isEasyReadEnabled && easyReadBody}
          />
        }
        {pageImages.filter((img) => img.positionAndSize === "f3").map(getImage)}
        {widget &&
          <WidgetWrapper rtl={rtl}>
            <div dangerouslySetInnerHTML={{ __html: widget }} />
          </WidgetWrapper>
        }
        {imageGalleriesWithId.length > 0 &&
          <Body>
            {imageGalleriesWithId.map((gallery) => (
              <ImageGallery
                key={gallery.id}
                title={gallery.title}
                frame={gallery.frame}
                images={gallery.images}
              />
            ))}
          </Body>
        }
        {hasFiles && files.map((filesection) => {
          const listsWithId = filesection.lists.filter((n) => n.files && n.files.length).map((n, index) => ({ id: index, ...n }))
          return (
            <Body key={filesection.title} className="file-section">
              {filesection.title && <h2>{filesection.title}</h2>}
              <ul>
                {listsWithId.map((list) => (
                  <li key={list.id}>
                    <FileList lang={lang} list={list} />
                  </li>
                ))}
              </ul>
            </Body>
          )
        })}
      </div>
      {isEasyReadEnabled && (easyReadPreamble || easyReadBody) && (lang === "sv" || lang === "en") && title.toLowerCase() !== "narkoswebbens ordlista" && title.toLowerCase() !== "anaesthesia web glossary" &&
        <p>
          <Link to={`/${lang}/${lang === "sv" ? "narkoswebbens-ordlista" : "anaesthesia-web-glossary"}`}>
            {intl.formatMessage({ id: "anaesthesiaWebGlossary" })}
          </Link>
        </p>
      }
      {logos.length > 0 &&
        <LogoWrapper {...(lang !== "sv" && lang !== "en" ? { lang: "en" } : {})}>
          {logos.map((logo) => {
            const src = `/${encodeURI(logo.image)}`

            return (
              <img
                key={`${logo.image}_${key}`}
                src={src}
                srcSet={`${src}?nf_resize=fit&w=200 200w, ${src}?nf_resize=fit&w=400 400w, ${src}?nf_resize=fit&w=640 640w`}
                sizes="(max-width: 640px) 100vw, 640px"
                alt={logo.altText}
                style={{ width: Math.min(75, (100 / logos.length)) + "%" }}
              />
            )
          })}
        </LogoWrapper>
      }
      {template === "cookies" && <CookieSettings manualMode update />}
      {children && <Body>{children}</Body>}
    </ContentWrapper>
  )
}

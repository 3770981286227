import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  position: relative;
  display: inline-block;

  ::before,
  span {
    display: block;
    width: 104.4%;
    height: 0;
    margin: 0 -2.2%;
    border-radius: 0.125rem;
    background: linear-gradient(#dadada 0%, #b7b7b7 100%);
  }

  ::before {
    content: "";
    padding-top: 3.2%;
  }

  img {
    display: block;
  }
`

const Bottom = styled.span`
  padding-top: 6.6%;
  margin-bottom: 5.2% !important;

  ::before,
  ::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 5.6%;
    height: 0;
    padding-top: 5.2%;
    border-radius: 0 0 9999px 9999px;
    background: linear-gradient(#c5c5c5 0%, #b7b7b7 100%);
  }

  ::before {
    left: 21.4%;
  }

  ::after {
    right: 21.4%;
  }
`

export default function RollupFrame({ children }) {
  return (
    <Wrapper>
      {children}
      <Bottom />
    </Wrapper>
  )
}

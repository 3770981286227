import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import LogoLink from "./LogoLink"
import ActionButton from "./ActionButton"
import PageMenu from "./PageMenu"
import LanguageDropdown from "./LanguageDropdown"
import HamburgerClose from "./icons/HamburgerClose"

const Wrapper = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  height: 3.5rem;
  width: 100%;
  background: linear-gradient(0deg, rgb(226, 0, 0) 0%, rgb(184, 0, 0) 100%);
`

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 40rem;
  margin: 0 auto;
  padding-right: 1rem;
  z-index: 10;

  > div {
    display: flex;
    align-items: center;
    height: 3.5rem;
  }

  @media (min-width: 820px) {
    max-width: 48.25rem;
  }
`

const StyledLogoLink = styled(LogoLink)`
  margin-right: auto;
  margin: 0.25rem;
  margin-top: 0.75em;
  margin-left: 0.6rem;
`

const PageMenuWrapper = styled.nav`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding-top: 5rem;
  overflow: auto;
  background: #fff;
  animation: menu-fade-in 0.3s forwards;

  &.close {
    animation: menu-fade-out 0.3s forwards;
  }

  @keyframes menu-fade-in {
    0%   { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes menu-fade-out {
    0%   { opacity: 1; }
    100% { opacity: 0; }
  }
`

const StyledActionButton = styled(ActionButton)`
  width: 3rem;
  height: 3rem;

  > *:nth-child(2) {
    max-width: 100%;
    max-height: 100%;
  }

  svg.hamburger-close-icon {
    width: 90%;
  }
`

const ActionButtonLabel = styled.span`
  position: absolute;
  display: block;
  bottom: .5625rem;
  width: 100%;
  color: white;
  font: 0.65rem/1 ${props => props.theme.headingFont};
`

const StyledLanguageDropdown = styled(LanguageDropdown)`
  ${props => props.hidden && "visibility: hidden;"}
`

let closeTimeout

const Header = ({ lang, menuItems, pages, noMenu = false, noLanguageDropdown = false }) => {
  const intl = useIntl()
  const [hamburgerIsToggled, setHamburgerIsToggled] = useState(false)
  const [hamburgerIsClosed, setHamburgerIsClosed] = useState(true)
  const uiLang = lang === "sv" ? "sv" : "en"

  useEffect(() => {
    document.body.style.overflow = hamburgerIsToggled ? "hidden" : "visible"
    return () => document.body.style.overflow = "visible"
  }, [hamburgerIsToggled])

  useEffect(() => {
    if (hamburgerIsToggled) {
      setHamburgerIsClosed(false)
    } else {
      closeTimeout = setTimeout(() => { setHamburgerIsClosed(true) }, 400)
      return () => clearTimeout(closeTimeout)
    }
  }, [hamburgerIsToggled])

  return (
    <Wrapper>
      <Container>
        <StyledLogoLink lang={uiLang} />
          <div>
            {!noLanguageDropdown &&
              <StyledLanguageDropdown
                left
                hidden={hamburgerIsToggled}
                uiLang={uiLang}
                activeLanguage={lang}
                ui={noMenu}
              />
            }

            {!noMenu &&
              <StyledActionButton
                lang={uiLang}
                aria-expanded={hamburgerIsToggled}
                onClick={() => {
                  setHamburgerIsToggled(!hamburgerIsToggled)
                }}
              >
                {intl.formatMessage({ id: "showMenu" })}
                <HamburgerClose fill="white" lang={uiLang} isActive={hamburgerIsToggled} />
              </StyledActionButton>
            }
          </div>
      </Container>
      {!hamburgerIsClosed && (
        <PageMenuWrapper
          className={hamburgerIsToggled ? "" : "close"}
          aria-label={intl.formatMessage({ id: "mainMenu" })}
          lang={uiLang}
        >
          <PageMenu lang={lang} menuItems={menuItems} pages={pages} />
        </PageMenuWrapper>
      )}
    </Wrapper>
  )
}

export default Header

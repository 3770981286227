import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import debounce from "lodash/debounce"
import get from "lodash/get"

import NwHelmet from "../components/NwHelmet"
import Header from "../components/Header"
import Footer from "../components/Footer"
import TextContent from "../components/TextContent"
import TryggwebbButton from "../components/TryggwebbButton"
import ActiveModal from "../components/modals/ActiveModal"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"
import { PageMetadataProvider } from "../pageMetadata"

import stripTags from "../utils/strip-tags"

const StyledFooter = styled(Footer)`
  margin-top: 2rem;
`

const Wrapper = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  background: ${props => props.theme.textPageContentBackgroundColor};

  @media (min-width: 820px) {
    background: ${props => props.theme.textPageBackgroundColor};
  }
`

export default function TextPageTemplate({
  data: { pagesYaml: page, translations, menuYaml, menuPages, ciceronYaml, allTextsYaml },
  pageContext: { lang, pagePath, title },
}) {
  const intl = useIntl()
  const BREAKPOINT_SMALL = 570
  const isSmallScreen = typeof window !== "undefined" && window.innerWidth < BREAKPOINT_SMALL
  const [activeEasyReadTitle, setActiveEasyReadTitle] = useState(page.easyReadTitle)
  const [activeEasyReadPreamble, setActiveEasyReadPreamble] = useState(page.easyReadPreamble)
  const [activeEasyReadBody, setActiveEasyReadBody] = useState(page.easyReadBody)

  const pageMetadata = {
    translations: translations.nodes,
  }

  const handleResize = debounce(() => {
    const isSmall = window.innerWidth < BREAKPOINT_SMALL

    setActiveEasyReadTitle(isSmall ? page.easyReadMobileTitle : page.easyReadTitle)
    setActiveEasyReadPreamble(isSmall ? page.easyReadMobilePreamble : page.easyReadPreamble)
    setActiveEasyReadBody(isSmall ? page.easyReadMobileBody : page.easyReadBody)
  }, 250)

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => { window.removeEventListener("resize", handleResize) }
  }, [])

  return (
    <PageMetadataProvider value={pageMetadata}>
      <Wrapper>
        <NwHelmet
          lang={page.noMultiLang ? (lang === "sv" ? "sv" : "en") : lang}
          path={pagePath}
          title={title || page.title}
          image={{
            url: `/assets/share-images/NW_Delning2_Vuxen_${lang === "sv" ? "Sve" : "Eng"}.jpg`,
            width: 1200,
            height: 630,
          }}
          desc={stripTags(get(allTextsYaml.nodes.find((x) => x.lang === lang), "text.html"))}
        />
        <Header
          lang={lang}
          noMenu={page.template === "no-menu" || page.template === "cookies"}
          menuItems={get(menuYaml, "items")}
          noLanguageDropdown={get(translations, "nodes", []).length < 2}
          pages={menuPages.nodes}
        />
        <main>
          <TextContent
            lang={lang}
            title={title || page.title}
            noMultiLang={page.noMultiLang}
            preamble={page.preamble && page.preamble.html}
            body={page.body && page.body.html}
            easyReadTitle={activeEasyReadTitle}
            easyReadPreamble={activeEasyReadPreamble && activeEasyReadPreamble.html}
            easyReadBody={activeEasyReadBody && activeEasyReadBody.html}
            images={page.images}
            imageGalleries={page.imageGalleries}
            files={page.files}
            widget={page.widget && page.widget.code}
            template={page.template}
          />
        </main>
        {lang === "sv" &&
          <TryggwebbButton lang={lang} room="adults" data={{ text: ciceronYaml.text }} />
        }
        <StyledFooter
          setLangAttr={!page.noMultiLang && lang !== "sv" && lang !== "en"}
          lang={lang === "sv" ? "sv" : "en"}
          title={intl.formatMessage({ id: "siteTitle" })}
          email={intl.formatMessage({ id: "emailAddress" })}
        />
      </Wrapper>
      <ActiveModal />
      <BrowserUpdateMessage />
      <CookieBanner lang={lang} />
    </PageMetadataProvider>
  )
}

export const pageQuery = graphql`
  query($lang: String, $contentLang: String, $_id: String) {
    pagesYaml(lang: { eq: $contentLang }, _id: { eq: $_id }) {
      title
      template
      noMultiLang
      preamble {
        html
      }
      body {
        html
      }
      easyReadTitle
      easyReadPreamble {
        html
      }
      easyReadBody {
        html
      }
      easyReadMobileTitle
      easyReadMobilePreamble {
        html
      }
      easyReadMobileBody {
        html
      }
      images {
        altText
        caption
        image
        positionAndSize
      }
      imageGalleries {
        title
        frame
        images {
          image
          altText
          caption
        }
      }
      files {
        title
        lists {
          description
          files {
            file
            thumbnail
            altText
            description
          }
        }
      }
      widget {
        code
      }
    }
    menuYaml(_id: { eq: "menu" }, lang: { eq: $lang }) {
      items {
        items {
          page
        }
        label
      }
    }
    translations: allPagesYaml(filter: { _id: { eq: $_id } }) {
      nodes {
        lang
        path
      }
    }
    menuPages: allPagesYaml(filter: { lang: { eq: $lang } }) {
      nodes {
        _id
        path
        title
      }
    }
    ciceronYaml(lang: { eq: $lang }, _id: { eq: "vuxna" }) {
      text
    }
    allTextsYaml(filter: { _id: { eq: "descriptionAdultPages" } }) {
      nodes {
        lang
        text {
          html
        }
      }
    }
  }
`
